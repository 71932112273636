

import React from 'react';
import {Helmet} from "react-helmet";
import Header from '../components/Header';
import Footer from "../components/Footer"


// Style
import './cgu.scss';


// markup
const Policy = () => {

  return (
    <main className={"cgu"}>
      <Helmet title={'Scorf - Politique de confidentialité'}>
          <html lang="fr" />
          <meta charSet="utf-8" />
          <meta name='title' content='Scorf'/>
          <meta name="description" content="Scorf construit un outil BI unique capable de comprendre la 'grammaire' comptable qui se cache derrière chaque base de données financière - Grand livre, écritures de journal. Cela lui permet de fournir des analyses avancées et un suivi en temps réel aux départements financiers des PME ainsi qu'aux analystes et consultants financiers externes." />
          <link rel="canonical" href="https://www.scorf.io/policy" />
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-63J6THBWF0"></script>
          <script dangerouslySetInnerHTML={{__html:`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-63J6THBWF0');`}}>
            
          </script>
        </Helmet>
      <title>Politique de confidentialité</title>
      <Header headerColor={'white'}/>
      <h1>Politique de confidentialité</h1>
      <p className="date">Dernière mise à jour le 29 mars 2021 </p>
      <div className="content">
        <p className='introduction'>Le traitement des données personnelles et le respect de la vie privée sont au centre de nos attentions. 

Aussi, SCORF s’engage à respecter la législation en vigueur en matière de protection de la vie privée et des données personnelles et, en particulier, le règlement européen sur la protection des données personnelles (RGPD) du 27 avril 2016. 

L’objectif de cette politique de confidentialité (ci-après « Politique de confidentialité ») est donc de vous : </p>

        <div className="lineIntroduction">
          <p>•</p>
          <p>permettre de communiquer vos informations personnelles en toute confiance et en toute confidentialité lorsque vous entrez en relation avec SCORF, par tous moyens et notamment lorsque vous utilisez le Site et l’application SCORF,  </p>
        </div>
        <div className="lineIntroduction">
          <p>•</p>
          <p>informer des finalités de traitement des données collectées par SCORF,  </p>
        </div>
        <div className="lineIntroduction">
          <p>•</p>
          <p>informer sur les mesures prises pour assurer la confidentialité et la sécurité des données personnelles, </p>
        </div>
        <div className="lineIntroduction">
          <p>•</p>
          <p>et d’une manière générale, vous éclairer sur vos droits en matière de données personnelles. </p>
        </div>


        <h3>1. Qui sommes-nous ? </h3>
        <div className="line">
          <p>Les données personnelles vous concernant sont traitées par la société SCORF, société par actions simplifiée au capital de 8 000 euros dont le siège social est situé 33 bis Route de la Reine – 92100 Boulogne-Billancourt, immatriculée au RCS de Nanterre sous le N° 853 834 059 (ci-après «SCORF»). </p>
        </div>

        <h3>2. Quand collectons-nous des données personnelles ?  </h3>
        <div className="line">
          <p>Lorsque vous entrez en relation avec SCORF, par quelque moyen que ce soit, notamment lorsque vous vous rendez sur le Site internet et utilisez celui-ci, vous pouvez être amené à communiquer un certain nombre de données personnelles vous concernant, c'est-à-dire des informations permettant directement ou indirectement votre identification. 

Vous serez amené à communiquer des données personnelles pour :  </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>créer votre compte personnel ou vous connecter à ce dernier,  </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>naviguer sur le Site internet,  </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>tout formulaire que vous remplissez sur le Site internet, </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>vous inscrire à notre newsletter,  </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>procéder à la souscription d’une offre, </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>publier un avis, </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>toute correspondance avec nous par téléphone, e-mail, réseaux sociaux, forum ou tout autre moyen, </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>signaler un problème ou demander une assistance, contacter le service client, </p>
        </div>
        <div className="line">
          <p>Suite à la création de votre compte et sous réserve d’y avoir consenti, vous pourrez recevoir des informations et offres commerciales de SCORF par communication électronique (newsletters, email, sms...). Ces emailings vous permettent de vous tenir informés de l'actualité de SCORF et des éventuels avantages dont vous pouvez bénéficier. Nous mesurons le taux d'ouverture de nos envois électroniques afin de les adapter au mieux à vos besoins. Certaines données personnelles qui peuvent vous être demandées sont obligatoires. Le caractère obligatoire des données récoltées est signalé par un astérisque, les autres données sont facultatives. L’absence de fourniture des données personnelles obligatoires entraine l’impossibilité d’accéder à l’ensemble des services offerts par SCORF. </p>
        </div>
        <div className="line">
          <p>Les produits et services proposés par SCORF et, plus particulièrement, le Site internet ne s’adressent pas aux mineurs. </p>
        </div>

        <h3>3. Quelles sont les données personnelles que nous collectons ? </h3>
        <div className="line">
          <p>SCORF est susceptible de collecter auprès de vous sur le Site internet les données personnelles suivantes :  </p>
        </div>
        <div className="line">
          <table>
            <tbody>
              <tr>
                <td>
                  Données d’identification 
                </td>
                <td>
                  Civilité, nom, prénom, Adresse mail, téléphone, l’identifiant et mot de passe associés à votre compte utilisateur, adresse de facturation 
                </td>
              </tr>
              <tr>
                <td>
                  Données de connexion / utilisation 
                </td>
                <td>
                  Adresse IP, historique de navigation, historique de commande, parcours de visite, système d’exploitation, informations sur les appareils à partir desquels vous vous rendez sur le Site internet, préférences et centres d'intérêt, etc.  
                </td>
              </tr>
              <tr>
                <td>
                  Données financières 
                </td>
                <td>
                  Données concernant votre entreprise et vos états financiers; Données issues de logiciels financiers tiers (logiciels de comptabilité, ERP) 
                </td>
              </tr>
              <tr>
                <td>
                  Données de paiement  
                </td>
                <td>
                  RIB / IBAN; Données de carte bancaire (4 derniers chiffres CB, date d’expiration, type de carte) 
                </td>
              </tr>
              <tr>
                <td>
                  Données de localisation   
                </td>
                <td>
                  Position géographique ou données de mouvements de l’appareil lors de l’utilisation de certaines fonctionnalités  
                </td>
              </tr>
              <tr>
                <td>
                  Vie professionnelle    
                </td>
                <td>
                  CV, formation professionnelle, lettre de motivation; Fonction, société/entreprise   
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="line">
          <p>Pour toutes autres données collectées par ailleurs, le formulaire de recueil de données décrira les règles applicables aux données ainsi collectées dans l’hypothèse où elles seraient différentes des règles figurant dans la présente politique. </p>
        </div>

        <h3>4. Pour quelles finalités sont-elles collectées ? </h3>
        <div className="line">
          <p>Au cours de la relation que vous entretenez avec SCORF, quelle que soit la nature de cette relation, SCORF est susceptible de collecter auprès de vous des données personnelles pour plusieurs raisons: </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>La gestion des accès à certains services sur le Site Internet,  </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>La fourniture des services proposés par SCORF sur le Site Internet, </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>La gestion des souscriptions d’offres et de la relation client (par exemple les factures, la comptabilité et en particulier la gestion des comptes clients, le suivi de la relation client, la gestion des impayés et des réclamations), </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>Le paiement de vos commandes : lorsque vous payez sur le Site internet, vos paiements sont sécurisés. SCORF ne collecte pas vos données de paiement mais seulement un identifiant de paiement et les 4 derniers chiffres de votre carte pour vous permettre de reconnaître la carte qui a servi au paiement. L'intégralité de vos coordonnées bancaires sont collectées uniquement par notre prestataire de services de paiement certifié STRIPE qui permet de garantir la sécurité des paiements.  </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>L’envoi de newsletters, sollicitations et messages promotionnels avec votre consentement,  </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>L’élaboration de statistiques commerciales et de fréquentation de nos services, </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>L’analyse des performances et la personnalisation du contenu présent sur le Site afin de proposer un contenu adapté à vos préférences (langage, affichage...), </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>La gestion des avis des utilisateurs sur les services ou contenus,  </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>La gestion des recrutements,  </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>Le respect de nos obligations légales et réglementaires, </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>La gestion de vos demandes relatives à vos droits tels que décrits à l’article 8. </p>
        </div>
        <div className="line">
          <p>Nous pouvons utiliser des données pour mieux comprendre nos clients ou à des fins de statistiques pour analyser l'activité du Site internet et améliorer les services que nous proposons.  </p>
        </div>
        <div className="line">
          <p>Les bases juridiques sur lesquelles nous nous appuyons pour traiter les données à caractère personnel incluent les traitements qui : </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>sont nécessaires pour l’exécution du contrat conclu entre vous et SCORF, tel que : assurer la souscription, le suivi, l’exécution et la gestion du contrat (gestion des souscriptions, création de votre compte personnel, paiement, facturation, gestion des impayés et contentieux éventuels, réponse à vos recherches ou demandes d’informations concernant les services proposés ou à vos réclamations etc.) dans le respect des conditions légales et réglementaires applicables; vous fournir les services que vous commandez et répondre à vos demandes ;  </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>sont nécessaires pour respecter les obligations légales et réglementaires qui s’imposent à SCORF telles que les déclarations sociales et fiscales et les obligations comptables; </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>sont nécessaires au respect de nos intérêts légitimes tels que le développement des activités de SCORF, l’amélioration des services, la recherche de nouveaux services, l’administration, la maintenance et l’amélioration du Site internet, la lutte contre la fraude, la gestion de votre compte client; </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>sont fondé sur le consentement de nos clients, lequel consentement peut ensuite être retiré à tout moment dans les conditions prévues à l’article 8 de la présente politique de confidentialité afin, notamment, de communiquer avec vous au sujet de nos services et vous fournir des informations sur nos services. </p>
        </div>




        <h3>5. Pendant combien de temps sont-elles conservées ?  </h3>
        <div className="line">
          <p>Au cours de la relation que vous entretenez avec SCORF, quelle que soit la nature de cette relation, SCORF est susceptible de collecter auprès de vous des données personnelles selon les modalités suivantes : </p>
        </div>
        <div className="line">
          <table>
            <thead>
              <tr>
                <td>
                  Finalité du traitement	 
                </td>
                <td>
                  Base juridique du traitement 	 
                </td>
                <td>
                  Données personnelles traitées
                </td>
                <td>
                  Durée de conservation 
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Création de votre compte personnel et connexion à ce dernier	 
                </td>
                <td>
                  Contrat 
                </td>
                <td>
                  Données d’identification, données de connexion / utilisation 
                </td>
                <td>
                  Durée de la relation contractuelle et 5 ans maximum à compter de la dernière connexion en archivage intermédiaire 
                </td>
              </tr>
              <tr>
                <td>
                  Gestion de la facturation et du fichier client 	 
                </td>
                <td>
                  Intérêt légitime, Obligations légales et réglementaires  
                </td>
                <td>
                  Données d’identification  
                </td>
                <td>
                  Jusqu’à 3 ans à compter de la fin de la relation contractuelle et 10 ans en archivage intermédiaire  
                </td>
              </tr>
              <tr>
                <td>
                  Gestion des paiements 	 
                </td>
                <td>
                  Contrat  
                </td>
                <td>
                  Données de paiement   
                </td>
                <td>
                  Jusqu’à la résiliation de votre abonnement   
                </td>
              </tr>
              <tr>
                <td>
                  Fourniture des prestations 	 
                </td>
                <td>
                  Contrat   
                </td>
                <td>
                  Données d’identification ; Données de connexion / utilisation; Données financières; Données de localisation    
                </td>
                <td>
                  Durée de la relation contractuelle et 5 ans maximum à compter de sa cessation en archivage intermédiaire   
                </td>
              </tr>
              <tr>
                <td>
                  Gestion et suivi des demandes émanant de prospects 	 
                </td>
                <td>
                  Consentement  
                </td>
                <td>
                  Données d’identification     
                </td>
                <td>
                  Jusqu’au retrait du consentement ou 3 ans à compter du dernier contact    
                </td>
              </tr>
              <tr>
                <td>
                  Réaliser des statistiques dans un but d’amélioration de nos produits et services 	 
                </td>
                <td>
                  Intérêt légitime   
                </td>
                <td>
                  Données d’identification ; Données de connexion / utilisation      
                </td>
                <td>
                  13 mois maximum     
                </td>
              </tr>
              <tr>
                <td>
                  Envoi de Newsletter  	 
                </td>
                <td>
                  Consentement    
                </td>
                <td>
                  Données d’identification       
                </td>
                <td>
                  Jusqu’au retrait du consentement ou 3 ans à compter du dernier contact      
                </td>
              </tr>
              <tr>
                <td>
                  Gestion du processus de recrutement   	 
                </td>
                <td>
                  Consentement ou intérêt légitime     
                </td>
                <td>
                  Données d’identification ; Vie professionnelle        
                </td>
                <td>
                  2 ans après le dernier contact       
                </td>
              </tr>
              <tr>
                <td>
                  Gestion des droits des personnes concernées    	 
                </td>
                <td>
                  Obligations légales et réglementaires      
                </td>
                <td>
                  Données d’identification         
                </td>
                <td>
                  3 mois maximum        
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="line">
          <p>Pour toutes autres données collectées par ailleurs, le formulaire de recueil de données décrira les règles applicables aux données ainsi collectées dans l’hypothèse où elles seraient différentes des règles figurant dans la présente Politique de confidentialité. </p>
        </div>

        <h3>6. A qui mes données personnelles sont-elles adressées ? </h3>
        <div className="line">
          <p>Les informations personnelles collectées par SCORF ne sont pas vendues à des tierces parties. Nous pouvons toutefois partager des données personnelles recueillies avec d'autres entités, à savoir : </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>les membres habilités du personnel de SCORF dans le cadre de la réalisation des missions résultant des finalités identifiées ci-avant ;  </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>les fournisseurs de services, en particulier ceux qui exécutent certaines fonctions pour notre compte, à savoir :  </p>
        </div>
        <div className="lineSubPuce">
          <p>○</p>
          <p>notre prestataire d’hébergement : Microsoft Azure <a href='https://azure.microsoft.com/fr-fr/' target='_blank' rel="noreferrer">(https://azure.microsoft.com/fr-fr/)</a> </p>
        </div>
        <div className="lineSubPuce">
          <p>○</p>
          <p>notre prestataire de services de paiement : Stripe <a href='https://stripe.com/fr' target='_blank' rel="noreferrer">(https://stripe.com/fr)</a> </p>
        </div>
        <div className="lineSubPuce">
          <p>○</p>
          <p>notre fournisseur de logiciel CRM : Pipedrive <a href='https://www.pipedrive.com/fr/' target='_blank' rel="noreferrer">(https://www.pipedrive.com/fr/)</a> </p>
        </div>
        <div className="lineSubPuce">
          <p>○</p>
          <p>nos fournisseurs de logiciel d’analyse et de performance </p>
        </div>
        <div className="lineSubPuce">
          <p>○</p>
          <p>nos fournisseurs de services publicitaires </p>
        </div>
        <div className="line">
          <p>Nous nous réservons le droit de divulguer vos données personnelles à d'autres parties dans les cas où la loi, une disposition réglementaire en vigueur, une ordonnance judiciaire ou une réglementation l’impose ou l’autorise, ou si cette divulgation est nécessaire dans le cadre d'une enquête, ou d'une procédure, sur le territoire national ou à l'étranger. 

Nous sommes également susceptibles de divulguer vos données à caractère personnel à tout acheteur potentiel en cas de cession d’une entité de SCORF ou de ses actifs. 

En plus des hypothèses listées ci-dessus, nous pouvons divulguer des renseignements non personnels à des tiers, comme des données statistiques agrégées. </p>
        </div>



        <h3>7. Comment mes données personnelles sont-elles protégées ?  </h3>
        <div className="line">
          <p>SCORF tient à assurer la sécurité des données que vous transmettez. Afin d’empêcher tout accès, usage, modification, destruction, perte, dommage ou divulgation non autorisé(e), des mesures techniques et organisationnelles appropriées ont été prises afin de protéger les données recueillies. En particulier, la sécurité de vos données est assurée : </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>grâce à une protection par identifiant et mot de passe ; </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>grâce à l’utilisation du standard de l’industrie TLS pour assurer la sécurité des échanges, </p>
        </div>
        <div className="line">
          <p>Pour aider dans cette démarche de protection de vos données, il est recommandé d’éviter d’utiliser un identifiant ou un mot de passe trop évident, ainsi que de changer régulièrement de mot de passe et veiller à ne communiquer votre mot de passe à personne. </p>
        </div>
        <div className="line">
          <p>Malheureusement, la transmission des informations, par le biais d’Internet notamment, n’est pas complètement sécurisée. Bien que SCORF s’engage à faire tout son possible pour protéger vos données à caractère personnel, SCORF ne peut pas garantir totalement la sécurité des données vous concernant que vous transmettez. </p>
        </div>
        <div className="line">
          <p>En cas de violation de vos données personnelles présentant un risque pour les droits et libertés des personnes concernées, sachez que SCORF a l’obligation de la notifier à l’autorité de contrôle compétente et de vous informer, dans les meilleurs délais, de toute violation de données personnelles susceptible d’engendrer un risque élevé pour vos droits et libertés, pour vous permettre de prendre les mesures utiles. </p>
        </div>


        <h3>8. Quels sont mes droits ? </h3>
        <div className="line">
          <p>Vous disposez du droit d’accéder à vos données personnelles, de les modifier, de les effacer, d’être oublié par SCORF, de limiter le traitement de vos données, de recevoir les données personnelles vous concernant, fournies à SCORF, dans un format structuré, fréquemment utilisé et lisible par machine, et, pour un motif légitime, de vous opposer au traitement des données personnelles. Lorsque le traitement est fondé sur votre consentement, vous disposez également du droit de le retirer. Vous disposez également du droit d’introduire une réclamation auprès de la CNIL ou d’une autorité de protection des données d’un Etat membre de l’Union européenne et de définir des directives post mortem. </p>
        </div>
        <div className="line">
          <p>Ce droit peut être exercé à tout moment : </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>Soit via le formulaire de contact disponible sur le Site Internet, </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>Soit par courrier à SCORF – 33bis Route de la Reine – 92100 Boulogne-Billancourt </p>
        </div>
        <div className="line">
          <p>Afin de permettre à SCORF de répondre rapidement, nous vous remercions d’indiquer vos nom, prénom, e-mail, adresse et si possible votre référence client. Certaines demandes d'exercice de vos droits (droit d'accès) devront être accompagnées de la photocopie d'un titre d'identité portant votre signature afin de vérifier votre identité et préciser l'adresse à laquelle doit vous parvenir la réponse. Une réponse vous sera alors adressée, sauf cas particulier, dans un délai d'un (1) mois suivant la réception de la demande. </p>
        </div>



        <h3>9. Mes données sont-elles transférées en dehors de l'Union Européenne ? </h3>      
        <div className="line">
          <p>Par principe, les données personnelles collectées n’ont pas vocation à être transférées en dehors de l’Union Européenne. Ces données personnelles sont hébergées sur un serveur situé dans l’Union européenne. Toutefois, dans la mesure où certains sous-traitants sont situés en dehors de l’Union européenne, des données vous concernant peuvent être transmises pour les besoins des finalités mentionnées plus haut à des sociétés situées dans des pays hors Union européenne. Préalablement au transfert hors Union Européenne, SCORF prendra toutes les mesures et garanties nécessaires pour sécuriser de tels transferts et s’engage à s'assurer que ce transfert est couvert par : </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>une décision d'adéquation de la Commission européenne conformément à l'article 45 du règlement européen sur la protection des données, </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>les clauses contractuelles types émises par la Commission européenne ou une Autorité de Contrôle conformément à l'article 46 du règlement européen sur la protection des données, </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>des règles d'entreprise contraignantes approuvées par une Autorité de Contrôle compétente en vertu de l'article 47 du règlement européen sur la protection des données. Si un transfert de données vers des pays non reconnus par la Commission européenne comme ayant un niveau de protection adéquat devait être envisagé, SCORF vous en informera. </p>
        </div>



        <h3>10. Politique de cookies </h3>
        <div className="line">
          <p>Un cookie est un petit fichier texte placé et stocké pendant une durée limitée sur votre ordinateur, tablette, smartphone ou tout autre appareil qui vous permet de naviguer sur le Site et ou l'Application SCORF. </p>
        </div>
        <div className="line">
          <p>Les cookies permettent à SCORF de vous reconnaître, de garder en mémoire certaines informations relatives à vos préférences ou à votre parcours sur notre Site ou notre Application (ci-après les "Services"), à comprendre la façon dont vous utilisez nos services. Les cookies nous permettent également de proposer des annonces publicitaires ciblées.  </p>
        </div>
        <div className="line">
          <p>10.1</p>
          <p>Par qui sont déposés les Cookies ? </p>
        </div>
        <div className="line">
          <p>Les cookies sont déposés sur votre terminal par SCORF ou par des tiers. </p>
        </div>
        <div className="line">
          <p>10.2</p>
          <p>Comment les Cookies sont-ils utilisés sur SCORF ?  </p>
        </div>
        <div className="line">
          <p>SCORF a recours a quatre types de cookies:  </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>Les cookies strictement nécessaires sont indispensables pour naviguer sur notre site Internet ou utiliser notre Application. Ils ne peuvent être paramètrés car leur suppression pourrait entraîner des difficultés de navigation sur nos Services. </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>Les cookies fonctionnels nous permettent de vous reconnaître lorsque vous revenez utiliser nos Services et de vous proposer un contenu adapté à vos préférences (langage, affichage...). </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>Les cookies de performance nous fournissent des données statistiques sur la fréquentation et l'utilisation de nos Services. Ces informations nous permettent de comprendre vos interactions avec nos Services et de les améliorer en conséquence. </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>Les cookies marketing ou de mesure d'audience permettent de conserver des traces de votre visite sur nos services, des pages que vous avez consultées et des liens sur lesquels vous avez cliqué dans le but d'afficher des annonces pertinentes et adaptées à vos centres d'intérêts. </p>
        </div>
        <div className="line">
          <p>10.3</p>
          <p>Pendant combien de temps conservons-nous vos Cookies ?  </p>
        </div>
        <div className="line">
          <p>Vos cookies sont conservés par SCORF pendant une durée de 12 mois avant d'être détruits.  </p>
        </div>
        <div className="line">
          <p>10.4</p>
          <p>Gestion des cookies  </p>
        </div>
        <div className="line">
          <p>Il vous est possible de désactiver les cookies à tout moment en utilisant notre outil de gestion des cookies ou de les supprimer en modifiant les paramètres de votre navigateur. 

Nous tenons toutefois à vous rappeler que le paramétrage des cookies est susceptible de modifier vos conditions d'accès à nos Services et votre confort de navigation. 

La configuration de chaque navigateur est différente. Elle est décrite dans le menu d'aide de votre navigateur qui vous permettra de savoir de quelle manière modifier vos souhaits en matière de cookies.  </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>Pour Chrome: <a href='https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en' target='_blank' rel="noreferrer">https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en</a> </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>Pour Internet Explorer: <a href='https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies' target='_blank' rel="noreferrer">https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies </a> </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>Pour Safari: <a href='https://support.apple.com/fr-fr/guide/safari/sfri11471/mac' target='_blank' rel="noreferrer"> https://support.apple.com/fr-fr/guide/safari/sfri11471/mac</a>  </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>Pour Firefox: <a href='https://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent?redirectlocale=fr&redirectslug=G%C3%A9rer+les+cookies' target='_blank' rel="noreferrer">https://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent?redirectlocale=fr&redirectslug=G%C3%A9rer+les+cookies</a> </p>
        </div>
        <div className="linePuce">
          <p>•</p>
          <p>Pour Opera: <a href='https://help.opera.com/Windows/10.20/fr/cookies.html' target='_blank' rel="noreferrer">https://help.opera.com/Windows/10.20/fr/cookies.html</a> </p>
        </div>        
        <div className="line">
          <p>En tout état de cause, vous trouverez plus d'informations sur la publicité comportementale et sur la protection de la vie privée sur Internet à cette adresse : <a href='https://www.youronlinechoices.com/fr/' target='_blank' rel="noreferrer">https://www.youronlinechoices.com/fr/.</a>  </p>
        </div>

        <h3>11. Modification de la Politique de confidentialité  </h3>      
        <div className="line">
          <p>SCORF se réserve le droit de modifier ou de façon plus générale d'actualiser à tout moment et sans préavis, la présente Politique de confidentialité. Toute modification substantielle sera signalée sur le Site internet et/ou, le cas échéant, par email. Nous vous invitons à consulter régulièrement cette page afin de vous tenir informé des modifications apportées à la Politique de confidentialité. </p>
        </div>

        <h3>12. Contacter SCORF  </h3>        
        <div className="line">
          <p>Pour toute question concernant le traitement de vos données personnelles ou pour toute autre remarque, demande ou réclamation concernant la confidentialité des données personnelles, vous pouvez contacter notre Délégué à la Protection des Données, par e-mail à l’adresse suivante : contact@scorf.io </p>
        </div>

      </div> 
      <Footer setHeaderColor={'white'} />
    </main>
  )
}

export default Policy

